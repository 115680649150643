import mva from '../image/mva-1.jpg'
const Psychology = [
    {
      header: "Psychological Therapy",
      content: `Psychological therapy, psychotherapy, or “talk therapy” is the use of psychology methods to help you change your thoughts, beliefs and behaviours to overcome life’s problems. Psychotherapy seeks to improve your mental health and well-being, resolve behaviours, beliefs, compulsions, thoughts and emotions, as well as to improve your relationships.`,
        
      content2: ``,
      image: ``,
    },
  ];
  
  export default Psychology;
  
  