import React from "react";
import OnlineCourses from "../allcourses/OnlineCourses";
import Back from "../common/back/Back";

const AllService = ({title,backgroundImage}) => {
  return (
    <div>
     <Back title={title} backgroundImage={backgroundImage} />
      {/* <div style={{marginTop:"10%", padding:"5%"}}> */}
      <OnlineCourses />
      {/* </div> */}
    </div>
  );
};

export default AllService;
