import React, { useRef } from "react";
import "./header2.css";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter, FaWhatsapp, FaSquarePhone } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import logo from "../../../image/logo.png";
import { FaTiktok } from "react-icons/fa6";
import { FaInstagramSquare } from "react-icons/fa";
import { MdMail } from "react-icons/md";
import { GrMapLocation } from "react-icons/gr";

import { useState } from "react";
import { FaBarsStaggered } from "react-icons/fa6";
import { online ,aboutNav } from "../../../dummydata";
import { useNavigate } from "react-router-dom";
import { IoIosArrowDropup, IoIosArrowDropdown } from "react-icons/io";

const Header = ({ blog }) => {

  const nav =useNavigate()

  const [navbar, setNavbar] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const menuRef = useRef(null);

  const changeBackground = () => {
    window.scrollY >= 10 ? setNavbar(true) : setNavbar(false);
  };
  window.addEventListener("scroll", changeBackground);

  const showMenu = () => {
    if (isOpen) {
      menuRef.current.classList.add("open-nav");
      setIsOpen(false);
    } else {
      menuRef.current.classList.remove("open-nav");
      setIsOpen(true);
    }
  };

  const handleMenu = () => {
    menuRef.current.classList.remove("open-nav");
  };

  const [isAbout,setIsAbout] = useState(false)

  return (
    <div className={navbar || blog ? "header active" : "header"}>
      <div className="wrapper-container">
        <div
          className="contact-info"
          style={{ display: navbar || blog ? "none" : "flex" }}
        >
          <h3
            style={{
              color: "black",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <span>
              <GrMapLocation color="purple" />
            </span>{" "}
            &nbsp;876 Webber Greens Drive NW, Edmonton, AB T5T4K5 &nbsp;&nbsp; |
            &nbsp;{" "}
            <span>
              <FaSquarePhone color="purple" />
            </span>
            &nbsp; 587-873-8808 &nbsp; &nbsp;| &nbsp;
            <span>
              <MdMail color="purple" />
            </span>{" "}
            &nbsp; reception@westhendayphysio.com{" "}
          </h3>
          <ul className="icon-list">
            <li>
              <a href="https://www.facebook.com/profile.php?id=61561067736200&mibextid=ZbWKwL">
                {"  "}
                <FaFacebookF size={20} color="purple" />
              </a>
            </li>
           
            <li>
              <a href="#">
                {" "}
                <FaTiktok size={20} color="purple"/>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/westhendayphysio?igsh=MWF4emtrODk3MzR6dg==">
                <FaInstagramSquare size={20} color="purple"/>
              </a>
            </li>
          </ul>
          <div className="header-btn" >
            <a href ='https://westhendayphysicaltherapy.janeapp.com/' target="_blank"> <b>Book Now</b></a>
          </div>

        </div>
        <div class="nav">
          <div class="logo-cont">
            <img src={logo} alt="West Henday Physical Therapy Logo" onClick={()=>nav('/')}/>
            <span>West Henday Physical Therapy</span>
          </div>

          <div className="navigation-bar">
            <ul className="nav-list" ref={menuRef}>
              <li>
                <div onClick={()=>{handleMenu();nav('/')}} >Home</div>
              </li>
              <li>
                <div onClick={()=>{handleMenu()}} className="header-about">
                  <div  className="header-about-hit"> <div onClick={()=>nav('/about')}>About</div>
                  
                    </div>  
                 
                  <section className="header-about-nav">
                    {
                      aboutNav.map((item,index)=>{
                        return(
                          <section className="header-inner-about" onClick={()=>nav(`${item.router}`)} key={index}>
                            <li>{item.name}</li>
                          </section>
                        )
                      })
                    }
                  </section>
                  <div className="header-about-mobile-nav" >
                    {aboutNav.map((item) => {
                      return (
                        <section className="header-inner-mobile-about" onClick={()=>{nav(`${item.router}`)}}>
                          <li>{item.name}</li>
                        </section>
                      );
                    })}
                  </div>
                </div>
             
              </li>
              <li>
                <div onClick={()=>{handleMenu()}}className="header-services">
                  <div onClick={()=>{nav('/our-services')}}>service</div>
                  <section className="header-services-nav">
                    {online.map((item,index) => {
                      return (
                        <section className="header-inner-service"onClick={()=>nav(`${item.router}`)} key={index}>
                          <img
                            src={item.cover}
                            alt="image"
                            width={"40px"}
                            height={"40px"}
                            style={{borderRadius:"20px"}}
                          />
                          <li >{item.courseName}</li>
                        </section>
                      );
                    })}
                  </section>
                  <div className="header-services-mobile-nav">
                    {online.map((item) => {
                      return (
                        <section className="header-inner-mobile-service" onClick={()=>{nav(`${item.router}`)}}>
                          <img
                            src={item.cover}
                            alt="image"
                            width={"40px"}
                            height={"40px"}
                            style={{borderRadius:"20px",objectFit:"contain"}}
                          />
                          <li>{item.courseName}</li>
                        </section>
                      );
                    })}
                  </div>
                </div>
              </li>

              {/* <li><a href='/#blogs'  onClick={handleMenu}>Blog</a></li> */}
              <li>
                <div onClick={()=>{handleMenu();nav('/contact')}}>Contact</div>
              </li>
            </ul>
            <span style={{ color: navbar ? "" : "white" }}>
              <FaBarsStaggered onClick={showMenu} />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;