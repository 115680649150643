import mva from '../image/mva-1.jpg'
const Mva_treatement = [
    {
      header: "MVA Treatment at West Henday",
      content: `After a motor vehicle accident (MVA), physical therapy is crucial for minimizing pain, stiffness, and long-term injuries. Our dedicated team offers personalized care from initial assessment to rehabilitation completion, addressing both physical and emotional aspects of recovery. With a focus on holistic well-being, we provide manual therapy, exercise prescription, education, and a range of specialized treatments to support your recovery journey. From concussion management to ergonomic training, we tailor each treatment plan to your unique needs, ensuring a safe and effective return to your pre-accident activities.`,
      ul: ``,
      li: [
        {
          context: "Comprehensive assessment and personalized care",
        },
        {
          context: "Holistic approach addressing physical and emotional aspects",
        },
        {
          context: "Range of specialized treatments including manual therapy and acupuncture",
        },
        {
          context: "Emphasis on injury prevention and functional rehabilitation",
        },
        {
          context: "Supportive and knowledgeable team dedicated to your recovery",
        },
      ],
      content2: ``,
      image: mva,
    },
  ];
  
  export default Mva_treatement;
  