import React, { useRef } from 'react'
import { MdArrowBackIos } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";
import "./review.css"

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import bgimage from "../../image/aboutus.jpg"
import profile from "../../image/logo.jpg"
// import required modules
import { Autoplay, Pagination } from 'swiper/modules';
import { GoStarFill } from "react-icons/go";

const Testimonial = () => {
    const sliderRef=useRef(null);

    const handlePrev =()=>{
        sliderRef.current.swiper.slidePrev();
    }
    const handleNext =()=>{
        sliderRef.current.swiper.slideNext();
    }
    return (
        <div className='testimonial'>
            <div className='client-info'>
                <h2>Client Review</h2>
                <p>What our client says about us</p>
                <div>
                    <span onClick={handlePrev}><MdArrowBackIos /></span>
                    <span onClick={handleNext}><MdArrowForwardIos /></span>
                </div>
            </div>
            <div className='testimonial-card-cont' style={{backgroundImage:`url(${bgimage})`}}>
                <div className='testimonial-swiper'>
                    <Swiper
                       
                        slidesPerView={3}
                        ref={sliderRef}
                        spaceBetween={30}
                        autoplay={{ delay: 5000 }}
                        modules={[Pagination,Autoplay]}
                        breakpoints={{
                            320: {
                              slidesPerView: 1,
                              spaceBetween: 20,
                            },
                            768: {
                              slidesPerView: 2,
                              spaceBetween: 40,
                            },
                            1024: {
                              slidesPerView: 3,
                              spaceBetween: 50,
                            },
                          }}
                        className="mySwiperTest"
                    >
                        <SwiperSlide className='slider-slider'>
                            <div className='testimonial-clients'>
                               
                                <img src={profile}/>
                                <h5>Sadiqa Hashim</h5>
                                <div className='ratings'> 
                                <span><GoStarFill/></span>
                                <span><GoStarFill/></span>
                                <span><GoStarFill/></span>
                                <span><GoStarFill/></span>
                                <span><GoStarFill/></span></div>
                                <p>Hi. I highly recommend this place, especially Joyce. She is brilliant and she knows how to heal your pain. She is very nice and sweet.They have very good customer service too.
                          </p>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide className='slider-slider'>
                        <div className='testimonial-clients'>
                               
                               <img src={profile}/>
                               <h5>ROGER SCOTT</h5>
                               <div className='ratings'> 
                               <span><GoStarFill/></span>
                               <span><GoStarFill/></span>
                               <span><GoStarFill/></span>
                               <span><GoStarFill/></span>
                               <span><GoStarFill/></span></div>
                    <p>Over all, it was a a very positive experience the months of physiotherapy and massage I received. </p>
                           </div>
                        </SwiperSlide>
                        <SwiperSlide className='slider-slider'>
                        <div className='testimonial-clients'>
                               
                               <img src={profile}/>
                               <h5>Michelle Comeau</h5>
                               <div className='ratings'> 
                               <span><GoStarFill/></span>
                               <span><GoStarFill/></span>
                               <span><GoStarFill/></span>
                               <span><GoStarFill/></span>
                               <span><GoStarFill/></span></div>
                                <p>Everyone here is always so friendly and the Physical Therapists are so knowledgeable. My children and I have had great treatments here and I would highly recommend them.</p>
                           </div>
                        </SwiperSlide>
                        <SwiperSlide className='slider-slider'>
                            <div className='testimonial-clients'>
                               
                                <img src={profile}/>
                                <h5>Monique Sawchuk </h5>
                                <div className='ratings'> 
                                <span><GoStarFill/></span>
                                <span><GoStarFill/></span>
                                <span><GoStarFill/></span>
                                <span><GoStarFill/></span>
                                <span><GoStarFill/></span></div>
                          <p>I had recently injured my back at work & I was sent for physiotherapy so I chose Innovation & got Kevin Button. He assessed my injury & gave me exercises and manual therapy which helped my injury.</p>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide className='slider-slider'>
                            <div className='testimonial-clients'>
                               
                                <img src={profile}/>
                                <h5>Lan Lam</h5>
                                <div className='ratings'> 
                                <span><GoStarFill/></span>
                                <span><GoStarFill/></span>
                                <span><GoStarFill/></span>
                                <span><GoStarFill/></span>
                                <span><GoStarFill/></span></div>
                            <p>Excellent and courteous staff! Pankaj is very friendly, knowledgeable and professional. He always takes great care to help his patients and listens all their concerns. I highly recommend him for all your physio needs.
                          </p>
                          </div>
                        </SwiperSlide>
                        
                    </Swiper>
            
            </div>
        </div>
    
    </div >
  )
}

export default Testimonial