import React from "react";
import Back from "../common/back/Back";
import ServiceCard from "./ServiceCard";

const Service = ({title,backgroundImage,serviceData}) => {
  return (
    <>
      <Back title={title} backgroundImage={backgroundImage} />
      <ServiceCard  serviceData={serviceData}/>
    </>
  );
};

export default Service;
