import React, { useEffect } from "react"
import Back from "../common/back/Back"
import "./contact.css"
import Faq from "../pricing/Faq"

const Contact = ({backgroundImage}) => {
  useEffect(()=>{
    window.scrollTo(0,0)
  })
  const map = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2371.8382506773546!2d-113.66966772374154!3d53.52494556134574!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53a02083f7a884f1%3A0x113bce6deef17fb0!2s876%20Webber%20Greens%20Dr%20NW%2C%20Edmonton%2C%20AB%20T5T%204K5%2C%20Canada!5e0!3m2!1sen!2sin!4v1717244979705!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"'
  return (
    <>
    
      <Back title='Contact Us' backgroundImage={backgroundImage}/>
      <section className='contacts padding'>
        <div className='container shadow flexSB'>
          <div className='left row'>
            <iframe src={map}></iframe>
          </div>
          <div className='right row'>
            <h1>Contact Us</h1>
            <p> Business address, email address and business hours which is Monday to Friday 7 am to 7 pm, Saturday, Sunday and Stat Holidays - closed.
<br /> Direct Billing - We offer direct billing to most insurance companies to make your life easier!</p>

            <div className='items grid2'>
              <div className='box'>
                <h4>ADDRESS:</h4>
                <p>876 Webber Greens Drive NW, Edmonton, AB T5T4K5</p>
              </div>
              <div className='box'>
                <h4>EMAIL:</h4>
                <p> reception@westhendayphysio.com</p>
              </div>
              <div className='box'>
                <h4>PHONE:</h4>
                <p> 587-873-8808</p>
              </div>
            </div>

            <form action=''>
              <div className='flexSB'>
                <input type='text' placeholder='Name' />
                <input type='email' placeholder='Email' />
              </div>
              <input type='text' placeholder='Subject' />
              <textarea cols='30' rows='10'>
                Create a message here...
              </textarea>
              <button className='primary-btn'>SEND MESSAGE</button>
            </form>

            <h3>Follow us here</h3>
            <span>FACEBOOK TIK-TOK INSTAGRAM </span>
          </div>
        </div>
        <Faq />
      </section>
    </>
  )
}

export default Contact
