import mva from '../image/mva-1.jpg'
const Post = [
    {
      header: "Post surgical physiotherapy",
      content: `Post surgical physiotherapy is a type of physiotherapy program aimed at patients who undergo orthopedic surgeries such as tendon repairs, ligament repairs, joint replacement surgeries, arthroscopic surgeries, spinal surgeries, reconstructive surgeries and fracture repairs. It is essential to follow the post-surgical protocols and educate patients on the do’s and don’t’s of surgeries, device personalized exercise program, and alleviate pain and discomfort with electrophysiological agents, and prevent contractures and deformities. Our experienced therapists at West Henday Physical Therapy can guide you towards your road to recovery. `,
    
      content2: ``,
      image: ``,
    },
  ];
  
  export default Post;
  
  