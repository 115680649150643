import React from "react";
import "./footer.css";
import { MdOutlineAccessTime } from "react-icons/md";
import { GrMapLocation } from "react-icons/gr";
import {
  FaSquarePhone,
  FaSquareFacebook,
  FaSquareWhatsapp,
  FaTiktok,
  FaSquareInstagram,
} from "react-icons/fa6";
import { MdMail } from "react-icons/md";
import logo from "../../../image/logo.png";
import image1 from "../../../image/footer1.jpg";
const Footer = () => {
  return (
    <div className="footer-main">
      <div className="footer-contact-us">
        <div className="footer-contact-us-sub">
          <section>
            <span>
              <GrMapLocation size={30} color="purple"/>
            </span>{" "}
            <div>
              <span style={{color:"purple"}}>Address</span>
              <p>876 Webber Greens Drive NW, Edmonton, AB T5T4K5</p>
            </div>
          </section>
          <section>
            <span>
              <FaSquarePhone size={30} color="purple" />
            </span>{" "}
            <div>
              <span style={{color:"purple"}}>Phone</span>
              <p> 587-873-8808</p>
            </div>
          </section>
          <section>
            <span>
              <MdMail size={30} color="purple" />
            </span>
            <div>
             <span style={{color:"purple"}}>Email</span> 
              <p> reception@westhendayphysio.com</p>
            </div>
          </section>
        </div>
      </div>
      <div className="Recent-news-contain">
        <div className="recent-news">
          <h2>Recent News</h2>
          <div className="recent-sub-contain">
            
            
          </div>
        </div>
        <div className="bussiness-hours">
          <h2>Bussiness Hours</h2>
          <ul>
            <li>
              <span>
                <MdOutlineAccessTime size={25}color="purple"/>
              </span>{" "}
              Monday to Friday 7 am to 7 pm
            </li>
            <li>
              <span>
                <MdOutlineAccessTime size={25} color="purple"/>
              </span>
              Saturday And Sunday Statutory Holidays Closed
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-logo">
        <div className="logo-div">
          <h1>West Henday Physical Therapy</h1>
        </div>
        <section className="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2371.8382506773546!2d-113.66966772374154!3d53.52494556134574!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53a02083f7a884f1%3A0x113bce6deef17fb0!2s876%20Webber%20Greens%20Dr%20NW%2C%20Edmonton%2C%20AB%20T5T%204K5%2C%20Canada!5e0!3m2!1sen!2sin!4v1717915413694!5m2!1sen!2sin"
            width="500"
            height="250"
            style={{ border: 0, borderRadius: "20px", paddingLeft: "10px" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </section>
        <section className="social-media-footer">
          <span>
            {" "}
            <a href="https://www.facebook.com/profile.php?id=61561067736200&mibextid=ZbWKwL">
            <FaSquareFacebook  /></a>
          </span>
          <span>
            {" "}
            <a href="#">
            <FaTiktok /></a>
          </span>
          <span>
            {" "}<a href="https://www.instagram.com/westhendayphysio?igsh=MWF4emtrODk3MzR6dg==">
            <FaSquareInstagram /></a>
          </span>
        </section>
        <section className="copy-rights">
          <p>
            ©️ copyright 2024. All Rights Reserved by West Henday Physical
            Therapy
          </p>
        </section>
      </div>
    </div>
  );
};

export default Footer;