import React from "react"
import Back from "../common/back/Back"
import Awrapper from "../about/Awrapper"
import "../about/about.css"
import Our from "../../image/ourmission.jpg"
import './corevalues.css'

const Corevalue = ({backgroundImage, missionImage}) => {
  return (
    <>
      <Back title='Core Values' backgroundImage={backgroundImage} />
      <section className='team padding'>
        <div className='container'>
          <div className='text-content'>
            <h1>Core Values</h1>
            <p>Our core values capture the essence of our rehabilitaion center's principles and beliefs. They emphasize the importance of patient-centered, holistic and ethical care and are captured by the acronym HEAL</p>
            <ul className="ul">
              <li><b>Holistic care:</b> We believe in a holistic approach to physiotherapy, addressing the physical, emotional, mental, and psychological factors to promote optimal healing.</li>
              <li><b>Expertise:</b> Our commitment to delivering care that consistently exceeds customer expectations is that core of our partice</li>
              <li><b>Accountability:</b>We will take responsibility for delivering patient centered care in a  responsible ,transparent,and ethical manner</li>
              <li><b>Leadership:</b> We will foster a culture of leadership among our staff ,valuing personal growth and development ,and empowering everyone to "lead without title".</li>
            </ul>
          </div>
          <div className='image-content'>
            <img src={Our}  />
          </div>
        </div>
      </section>
      <Awrapper />
    </>
  )
}

export default Corevalue;
