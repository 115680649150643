import React, { useEffect } from "react";
import "./service.css";
import { MdOutlineStarPurple500 } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const ServiceCard = ({ serviceData }) => {

  const nav = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    console.log(serviceData[0]);
  }, []);



  return (
    <div className="service-card">
      <div className="service-one">
        <h1 className="service-card-header">{serviceData[0].header}</h1>
        <p>{serviceData[0].content && serviceData[0].content}</p>
        <p> {serviceData[0].ul && serviceData[0].ul}</p>
        <ul className="service-ul">
          {serviceData[0].li &&
            serviceData[0].li.map((item) => (
              <li className="service-li">
                <span>
                  <MdOutlineStarPurple500 size={20} />
                </span>
                {item.context}
              </li>
            ))}
        </ul>

       <button className="service-btn" onClick={() => window.open('https://westhendayphysicaltherapy.janeapp.com/', '_blank')}>Book Now</button>
        <p>{serviceData[0].content2 && serviceData[0].content2}</p>
      </div>
      <div
        className="service-two"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={serviceData[0].image}
          alt="service"
          className="service-image"
        />
      </div>
    </div>
  );
};

export default ServiceCard;
