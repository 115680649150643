import mva from '../image/mva-1.jpg'
const Laser = [
    {
      header: "Laser Therapy",
      content: `Laser Therapy is the application of red and infrared light over injuries to improve soft tissue healing resulting in relief if acute and chronic conditions. Laser therapy uses monochromatic light emission from a high intensity super luminous diode to treat musculoskeletal injuries, chronic and degenerative conditions to heal wounds. The light source is placed in contact with the skin allowing the photon energy to penetrate the soft tissue, where it interacts with various intracellular biomolecules resulting in the restoration of normal cell functions and enhancement of the body’s healing processes.`,        
      content2: ``,
      image: ``,
    },
  ];
  
  export default Laser;
  
  