import React from "react"
import "./courses.css"
import { online } from "../../dummydata"
import Heading from "../common/heading/Heading"
import { useNavigate } from "react-router-dom"

const OnlineCourses = () => {

  const nav = useNavigate()
  return (
    <>
     <div>
     <section className='online'>
        <div className='container'>
          <Heading subtitle='services' title='services we offer' />
          <div className='content grid3'>
            {online.map((val,index) => (
              <div className='box' onClick={()=>nav(`${val.router}`)} key={index}>
                <div className='img'>
                  <img src={val.cover} />
                  <img src={val.hoverCover} alt='' className='show' />
                </div>
                <h1>{val.courseName}</h1>
                <span>{val.course}</span>
              </div>
            ))}
          </div>
        </div>
      </section>
     </div>
    </>
  )
}

export default OnlineCourses
