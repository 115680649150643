import mva from '../image/mva-1.jpg'
const Shock = [
    {
      header: "Shockwave Therapy",
      content: `Shockwave therapy is a non-invasive treatment that involves creating a series of low energy acoustic wave pulsations that are directly applied to an injury through a person’s skin via a gel medium. The concept and technology originally evolved from the discovery that focused sound waves could break down kidney and gallstones. Generated shockwaves have proven successful in several scientific studies for the treatment of chronic conditions. Shockwave therapy is its own treatment for a lingering injury, or pain resulting from illness. You don’t need painkillers with it - the purpose of the therapy is to trigger the body’s own natural healing response. Many people report that their pain is reduced, and mobility improved after the first treatment.`,
      ul: ``,
      li: [
        {
          context: "Accelerated Healing: Experience significant improvements in just 3-6 visits, enhancing tissue regeneration and reducing recovery time.",
        },
        {
          context: "Non-Invasive: Say goodbye to anesthesia and surgical risks, as Shockwave Therapy offers a safe alternative for pain relief and healing",
        },
        {
          context: "Versatile Applications: From musculoskeletal conditions to chronic stress and erectile dysfunction, Shockwave Therapy addresses a wide range of ailments.",
        },
        {
          context: "Minimal Side Effects: Enjoy a comfortable treatment experience with temporary effects like soreness and redness, quickly resolving to reveal lasting benefits.",
        },
       
      ],
      content2: ``,
      image: ``,
    },
  ];
  
  export default Shock;
  
  