import image1 from '../image/about-wrapper2.jpg'
const manualTherapy = [
  {
    header: "Manual Therapy Treatment in Edmonton",
    content: `Physical therapists receive post-graduate training in manual therapy in Canada through 
    the Canadian Physiotherapy 
    Orthopaedic Division. Our skilled manual therapists are available to treat all your mobility needs.`,
    ul: `Manual Therapy is a hands-on approach physical therapists use
     to treat neuromuscular and musculoskeletal conditions. It is integral to any 
     treatment plan as it promotes optimal movement, function and pain relief.
      Therapists can perform Manual Therapy on various structures in the body, 
    including joints, muscles, connective tissue and nerve tissue.
     It encompasses a variety of techniques, such as:`,
    li: [
      {
        context: "joint mobilizations",
      },
      {
        context: "Manipulations"
      },
      {
        context: "soft tissue release",
      },
      {
        context: "Active release technique",
      },
      {
        context: "Muscle energy technique",
      },
    ],
     image:image1,
  },
];

export default manualTherapy
