const ConcussionManagement = [
    {
      header: "Expert Concussion Management at West Henday Physio",
      content: `West Henday  Physical Therapy provides expert concussion treatment in Edmonton and its surroundings.

      Concussion is a traumatic injury to the brain caused by direct force to the head and neck or other parts of the body, resulting in the transmission of force to the head. This force may occur following a motor vehicle collision, sport-related injury or after falling. A range of signs and symptoms may or may not involve loss of consciousness.
      
      `,
      ul: ``,
      li: [
     
      ],
      content2: `These acute clinical signs and symptoms result from functional disturbances rather than structural injury to the brain. The disequilibrium can lead to headaches and fatigue as the brain works harder to function normally. Usual structural neuroimaging studies will not show any abnormalities. However, it is essential to be assessed by a primary care practitioner (e.g. physician, on-field therapist) to rule out other structural damage to the brain following the initial incident (which may include neuroimaging studies).`,
      image: "",
    },
  ];
  
  export default ConcussionManagement;
  