import React, { useEffect } from "react"
import Heading from "../common/heading/Heading"
import "./about.css"
import { homeAbout } from "../../dummydata"
import Awrapper from "./Awrapper"
import Aos from "aos"
import 'aos/dist/aos.css'
const AboutCard = () => {
  useEffect(()=>{
    Aos.init({
      duration:600,
      easing:'ease',
      delay:100
    })
  })
  return (
    <>
      <section className='aboutHome' data-aos='zoom-in'>
        <div className='container flexSB '>
          <div className='left row'>
            <img src='https://www.stayfitphysio.ca/wp-content/uploads/2019/07/Physiotherapy-Ottawa-1.png' alt='' />
          </div>
          <div className='right row'>
            <Heading subtitle='Empowering Health' title='Transforming Lives' />
            <div className='items'>
              {homeAbout.map((val) => {
                return (
                  <div className='item flexSB about-sub'>
                  <div className='img'>
                  <img src={val.cover} alt='' />
                </div>
                    <div className='text'>
                      <h2>{val.title}</h2>
                      <p>{val.desc}</p>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </section>
      <Awrapper />
    </>
  )
}

export default AboutCard
