import image1 from '../image/accu-image.jpg'
const AccupuntureTherapy = [
  {
    header: "Acupuncture treatment in Edmonton",
    content: `Acupuncture, an ancient healing practice, is seamlessly integrated into our physiotherapy 
    services to address pain, inflammation, and nervous system dysfunction. Through the strategic insertion 
    of thin needles at specific points on the body, acupuncture stimulates the body's natural healing processes, 
    promoting pain relief, improved circulation, and overall well-being.

    Grounded in Traditional Chinese Medicine principles,
     our experienced physiotherapists blend ancient wisdom with modern evidence-based 
     techniques to deliver personalized treatments tailored to each individual's needs. 
     With a focus on restoring the body's balance and enhancing rehabilitation outcomes, 
     acupuncture offers holistic solutions for a wide range of conditions, from acute injuries to chronic illnesses.`,
    ul: ``,
    li: [
      {
        context: "Reduces pain and inflammation",
      },
      {
        context: "Normalizes nervous system function",
      },
      {
        context: "Stimulates the body's natural healing response",
      },
      {
        context: "Enhances rehabilitation outcomes",
      },
      {
        context: "Personalized treatments tailored to individual needs",
      },
    ],
    content2: ``,
    image: image1,
  },
];

export default AccupuntureTherapy;
