import React from 'react';
import "./clients.css";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import image1 from '../../image/clients/image1.png';
import image2 from '../../image/clients/image2.png';
import image3 from '../../image/clients/image3.png';
import image4 from '../../image/clients/image4.png';
import image5 from '../../image/clients/image5.png';
import image6 from '../../image/clients/image6.png';
import image7 from '../../image/clients/image7.png';
import image8 from '../../image/clients/image8.png';
import image9 from '../../image/clients/image9.png';

const Clients = () => {
    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 1000,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };

    return (
        <div>
            <div className='techno' data-aos="fade-up">
                <div className='wrapper-container'>
                    <div className='techno-cont'>
                        <h1>We Direct Bill</h1>
                        <div className='slider-cont'>
                            <Slider {...settings}>
                                <div className='techno-stack react'>
                                    <img src={image1} alt="Client" />
                                </div>
                                <div className='techno-stack postgres'>
                                    <img src={image2} alt="Client" />
                                </div>
                                <div className='techno-stack node'>
                                    <img src={image3} alt="Client" />
                                </div>
                                <div className='techno-stack mysql'>
                                    <img src={image8} alt="Client" />
                                </div>
                                <div className='techno-stack mongodb'>
                                    <img src={image5} alt="Client" />
                                </div>
                                <div className='techno-stack'>
                                    <img src={image4} alt="Client" />
                                </div>
                                <div className='techno-stack react'>
                                    <img src={image6} alt="Client" />
                                </div>
                                <div className='techno-stack'>
                                    <img src={image7} alt="Client" />
                                </div>
                                <div className='techno-stack angular'>
                                    <img src={image9} alt="Client" />
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Clients;
