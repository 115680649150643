import React from "react"
import "../blog/blog.css"
import { blog } from "../../dummydata"
import Heading from "../common/heading/Heading"
import { useNavigate } from "react-router-dom"

const Hblog = () => {
  const nav = useNavigate()
  return (
    <>
      <section className='blog'>
        <div className='container'>
          <Heading subtitle='' title='' />
          <div className='grid2'>
            {blog.slice(0, 3).map((val) => (
              <div className='items shadow blog-whole-contain'>
                <div className='text blog-sub-container'>
                 
                  <h1>{val.title}</h1>
                  <p>{val.desc}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default Hblog
