import "./App.css";
import Header from "./components/common/header/Header";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from "./components/about/About";
import CourseHome from "./components/allcourses/CourseHome";
import Team from "./components/team/Team";
import Pricing from "./components/pricing/Pricing";
import Blog from "./components/blog/Blog";
import Contact from "./components/contact/Contact";
import Footer from "./components/common/footer/Footer";
import Home from "./components/home/Home";
import Service from "./components/service/Service";
import image1 from './image/back.webp'
import AllService from "./components/allService/AllService";
import manualTherapy from './servicesData/Manualservices'
import AccupuntureTherapy from './servicesData/accuputureService'
import image2 from './image/image2.jpg'
import Mva_treatement from "./servicesData/MVATreatment";
import ConcussionManagement from "./servicesData/ConcussionManagement";
import DryNeedling from "./servicesData/DryNeedling";
import accu from './image/acupuncture-image.jpg'
import manual from './image/manual.jpg'
import Dryneed from './image/dryneed.jpg'
import Conmangement from './image/Conman.jpg'
import mva from './image/mva-treament.jpg'
import aboutus from "./image/aboutus.jpg"
import services from "./image/services.jpg"
import Msg from "./image/massagee.jpg"
import Psy from "./image/psy.jpg"
import Pelvic from "./image/pelvic.jpg"
import sportss from "./image/sports.jpg"
import Vesti from "./image/vesti.jpg"
import Shocks from "./image/shock.jpg"

import Post from "./servicesData/post"
import wcb from "./servicesData/wcb"
import sports from "./servicesData/sports"
import vestibular from "./servicesData/vestibular"

import Ourvision from "./image/ourvision.jpg"
import Ourmission from "./image/ourmission.jpg"
import contacc from "./image/contact.jpg"

import team from "./image/team.jpg"
import MassageTherapy from "./servicesData/Massagetherapy";
import Hblog from "./components/home/Hblog";
import pelvic from "./servicesData/pelvic"
import pshyco from "./servicesData/pshycology"
import shock from "./servicesData/shock"
import laser from "./servicesData/laser"
import OurVision from "./components/about/ourvision";
import OurMission from "./components/about/ourmission";
import Corevalue from "./components/about/corevalues";

// import 
function App() {
  return (
    <>
      <Router>
        <Header />
        <div className="main-cointainer">
          <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/about" element={<About backgroundImage={aboutus}/>} /> */}
          <Route path="/courses" element={<CourseHome backgroundImage={image1}/>} />
          <Route path="/our-services" element={<AllService title={"Our Services"} backgroundImage={image2}/>} />
          <Route path="/manual-service" element={<Service title={'Manual Therapy'} backgroundImage={manual} serviceData={manualTherapy}/>} />
          <Route path="/accupuncture-service" element={<Service title={'Acupunture service'} backgroundImage={accu} serviceData={AccupuntureTherapy}/>} />
          <Route path="/mva-treatement-service" element={<Service title={'MVA Treatment'} backgroundImage={mva} serviceData={Mva_treatement}/>} />
          <Route path="/concussion-management-service" element={<Service title={'Concussion Management'} backgroundImage={Conmangement} serviceData={ConcussionManagement}/>} />
          <Route path="/dry-neddling" element={<Service title={'Dry Needling'} backgroundImage={Dryneed} serviceData={DryNeedling}/>} />
          <Route path="/message-therapy" element={<Service title={'Massage Therapy'} backgroundImage={Msg} serviceData={MassageTherapy}/>} />
          <Route path="/psychology" element={<Service title={'Psychology'} backgroundImage={Psy} serviceData={pshyco}/>} />
          <Route path="/pelvic-floor-physiotherapy-service" element={<Service title={'Pelvic Floor Physiotherapy'} backgroundImage={Pelvic} serviceData={pelvic}/>} />
          <Route path="/sports-physiotherapy-service" element={<Service title={'Sports Physiotherapy'} backgroundImage={sportss} serviceData={sports}/>} />
          <Route path="/vestibular-rehab-service" element={<Service title={'Vestibular Rehab'} backgroundImage={Vesti} serviceData={vestibular}/>} />
          <Route path="/wcb-physiotherapy" element={<Service title={'WCB Physiotherapy'} backgroundImage={manual} serviceData={wcb}/>} />
          <Route path="/post-surgery" element={<Service title={'Post Surgery'} backgroundImage={manual} serviceData={Post}/>} />
          <Route path="/shockwave-therapy" element={<Service title={'Shockwave Therapy'} backgroundImage={Shocks} serviceData={shock}/>} />

          <Route path="/laser-therapy" element={<Service title={'Laser Therapy'} backgroundImage={manual} serviceData={laser}/>} />


          <Route path="/ourvision" element={<OurVision backgroundImage={Ourvision} ></OurVision>} />
          <Route path="/ourmission" element={<OurMission backgroundImage={Ourmission} ></OurMission>} />
          <Route path="/corevalue" element={<Corevalue backgroundImage={image1} ></Corevalue>} />



          <Route path="/team" element={<Team backgroundImage={team}/>} />
          <Route path="/about" element={<Hblog  />} />

          <Route path="/pricing" element={<Pricing backgroundImage={services}/>} />
          <Route path="/journal" element={<Blog backgroundImage={team}/>} />
          <Route path="/contact" element={<Contact backgroundImage={contacc}/>} />
        </Routes>
        </div>
        <Footer />  
      </Router>
    </>
  );
}

export default App;
