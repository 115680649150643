import mva from '../image/mva-1.jpg'
const WCB = [
    {
      header: "WCB Physiotherapy",
      content: `Work place injury is the result of any work-related event that causes a need for medical treatment and/or time away from work and can include slips and falls, repetitive motion injuries, overexertion, motor vehicle accidents, and heavy machinery injuries. This can impact one’s quality of life and can interfere with daily activities. Physiotherapists at West Henday Physical Therapy are experts in treating work place injuries by helping them get back to pre-injury level and to achieve optimum functions.`,
      
      content2: ``,
      image: ``,
    },
  ];
  
  export default WCB;
  
  