import React from "react"
import Back from "../common/back/Back"
import Awrapper from "../about/Awrapper"
import "../about/about.css"
import "./ourvision.css"
import Our from "../../image/ourvisions.jpg"

const OurVision = ({backgroundImage}) => {
  return (
    <>
      <Back title='Our Vision' backgroundImage={backgroundImage} />
      <section className='team padding'>
        <div className='container'>
        <div className='text-content'>
            <h1>Our Vision</h1>
            <p>We are a popular clinic located in West Edmonton near the Lewis Estates Transit Centre. We are a family-based business that has been serving the neighbouring communities since 2019. Our team is committed to giving knowledgeable, professional, and personalized care, to help prevent and heal injuries. We are contracted to treat all types of patients’, this includes, WCB, MVA, and AHS funded visits. We encourage our patients and the community to be proactive and consistent with their health, resulting in a maximum health and performance.</p>
          </div>
          <div className='image-content'>
            <img src={Our} alt='Our Mission' />
          </div>
        </div>
      </section>
      <Awrapper />
    </>
  )
}

export default OurVision
