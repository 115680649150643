import image1 from '../image/about-wrapper2.jpg'

const MassageTherapy = [
    {
      header: "Transform Your Well-Being with Professional Massage Therapy at West Henday Physical Therapy",
      content: `At West Henday Physio,our experienced massage therapists provide personalized treatments to relieve pain, alleviate illnesses, promote relaxation, improve muscle flexibility, and movement range. From injury recovery to stress relief, our comprehensive massage services offer a non-pharmaceutical approach to pain management and relaxation. With a focus on enhancing your body's natural healing processes, our therapists utilize a variety of techniques tailored to your preferences and goals, ensuring a rejuvenating and satisfying experience with every session.`,
      ul: ``,
      li: [
        {
            context: "Targeted relief for muscular pain and spasms",
        },
        {
            context: "Release of muscle knots and tension",
        },
        {
            context: "Promotes relaxation and stress reduction",
        },
      
      
     
      ],
      content2: ``,
      image: image1,
    },
  ];
  
  export default MassageTherapy;
  