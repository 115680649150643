import mva from '../image/mva-1.jpg'
const Pelvic = [
    {
      header: "Pelvic Floor Physiotherapy in Edmonton",
      content: `Pelvic floor physiotherapy is the assessment and treatment of pelvic floor muscles, which are responsible for a variety of functions such as:`,
      ul: ``,
      li: [
        {
          context: "Tailored Treatments Our therapists personalize treatments to meet individual needs, ensuring comprehensive care.",
        },
        {
          context: "Enhanced Quality of Life Effective management of bowel and bladder concerns promotes overall well-being.",
        },
        {
          context: "Myth Clarification Dispelling common misconceptions about pelvic floor issues fosters better understanding and proactive treatment.",
        },
        
      ],
      content2: `It is estimated that 1 in 3 women will suffer from pelvic floor dysfunction in their lifetime. `,
      image: ``,
    },
  ];
  
  export default Pelvic;
  