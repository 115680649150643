import mva from '../image/mva-1.jpg'
const Sports = [
    {
      header: "Sports Physiotherapy",
      content: `Sports physiotherapy is a specialized subdivision of physiotherapy dedicated to assessment and rehabilitation of injuries related to sports and exercises. Treatment for sports injuries varies widely, depending on the type and severity. Common types of sports injuries are muscle strains and strains, ligament tears, meniscus tears, tendon ruptures, joint dislocation, and fractures. Our skillful and knowledgeable physiotherapists at West Henday Physical Therapy can perform:`,
            ul: ``,
      li: [
        {
          context: "A comprehensive assessment",
        },
        {
          context: "Identify the root cause of injury",
        },
        {
          context: "Develop an individualized treatment plan",
        },
        {
          context: "Improve strength and mechanics",
        },
        {
          context: "Apply realistic timescales for their return to sports",
        },
        {
            context: "Educate patients on injury prevention",
        },
      ],
      content2: ``,      image: ``,
    },
  ];
  
  export default Sports;
  