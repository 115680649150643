import mva from '../image/mva-1.jpg'
const Vestibular = [
    {
      header: "Vestibular Rehabilitation",
      content: `Vestibular rehab involves exercises and maneuvers that help manage dizziness, vertigo and balance issues. Vestibular therapy helps people with conditions such as vertigo, Meniere’s disease, benign paroxysmal positional vertigo, labyrinthitis, vestibular neuritis, and migraine headaches. Other problems that arise secondary to vestibular disorder are nausea, vomiting, difficulty with focus and concentrate and fatigue. Vestibular disorders can impact quality of life and can contribute to extreme frustration and emotional disturbances such as anxiety and depression. Vestibular rehabilitation is a specialized branch of physiotherapy which involves exercises-based program intended to alleviate both primary and secondary problems. Our specialists at West Henday Physical Therapy are well trained in managing dizziness, vertigo and imbalance issues with a help of customized exercise program and education.`,
      ul: ``,
      li: [
        {
          context: "Symptom Reduction VRT helps alleviate dizziness, improve balance, and stabilize vision, enhancing overall quality of life.",
        },
        {
          context: "Fall Prevention By addressing vestibular issues, VRT reduces fall risk and promotes safety during daily activities.",
        },
        {
          context: "•	 Individualized Approach Our experienced therapists tailor treatment plans to address specific vestibular conditions and symptoms.",
        },
        {
          context: "Comprehensive Assessment Thorough evaluations allow us to understand the root cause of your symptoms and customize treatment accordingly.",
        },
       
      ],
      content2: ``,
      image: ``,
    },
  ];
  
  export default Vestibular;
  
  