import React, { useEffect } from "react";
import Heading from "../../common/heading/Heading";
import "./Hero.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import homeImage from '../../../image/HomeB.jpg';

const Hero = () => {

  const nav = useNavigate()
  useEffect(() => {
    Aos.init({
      duration: 600,
      easing: "ease",
      delay: 100,
    });
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <section className="hero" data-aos="fade-up" style={{ backgroundImage: `url(${homeImage})` }}>
        <div className="container">
          <div className="row">
            <Heading
              subtitle="WELCOME TO OUR CLINIC"
              title="Get comfort with our Expertise Mentors and Clinicians"
            />
            <p>
              At West Henday Physio we're more than just a
              physical therapy clinic. We're your partners in health, dedicated to
              providing compassionate care and empowering you to live life to
              the fullest.
            </p>
            <div className="button btn-home">
              {/* <button className="primary-btn" onClick={()=>nav('/contact')}>
                GET STARTED NOW <i className="fa fa-long-arrow-alt-right"></i>
              </button> */}
              {/* <button onClick={()=>nav('/all-service')}>
                VIEW SERVICES <i className="fa fa-long-arrow-alt-right"></i>
              </button> */}
            </div>
          </div>
        </div>
      </section>
      {/* <div className='' style={{marginTop:"10px"}}></div> */}
    </>
  );
};

export default Hero;
