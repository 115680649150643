import React from "react"
import AboutCard from "../about/AboutCard"
import Hblog from "./Hblog"
import HAbout from "./HAbout"
import Hero from "./hero/Hero"
import Hprice from "./Hprice"
import Faq from "../pricing/Faq"
import OnlineCourses from "../allcourses/OnlineCourses"
import Testimonial from "../review/Review"
import Clients from "../clients/Clients"

const Home = () => {
  return (
    <>
      <Hero />
      {/* <AboutCard /> */}
      {/* <HAbout /> */}

      <OnlineCourses />
      <Testimonial />

      <Clients />
      
      {/* <Testimonal /> */}
      {/* <Hblog /> */}
      {/* <Faq /> */}
      {/* <Hprice /> */}
    </>
  )
}

export default Home
