import image1 from '../image/about-wrapper2.jpg'

const DryNeedling = [
    {
      header: "Experience Relief with Dry Needling Specialists at West Henday Physical Therapy",
      content: `Dry needling, a modern treatment for muscular pain, is gaining popularity for its effectiveness in releasing muscle knots and relieving pain and spasms. Our specialists at West Henday Physio in Edmonton utilize fine filiform needles to target trigger points in muscles, promoting relaxation and facilitating natural healing processes. With precise insertion techniques and personalized treatment plans, our experienced practitioners offer safe and effective dry needling sessions tailored to your specific needs, helping you find relief and improve your overall well-being.   
      `,
      ul: ``,
      li: [
        {
            context: "Targeted relief for muscular pain and spasms",
        },
        {
            context: "Release of muscle knots and tension",
        },
        {
            context: "Certified practitioners trained in specialized dry needling techniques",
        },
     
      ],
      image: image1,
    },
  ];
  
  export default DryNeedling;
  